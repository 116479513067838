<template>
  <div>
    <van-sticky>
      <van-nav-bar
        title="品牌选择"
        left-text="返回"
        left-arrow
        @click-left="onClickLeft"
      />
    </van-sticky>

    <div v-if="indexList && indexList.length > 0">
      <van-index-bar :sticky-offset-top="46" :index-list="indexList">
        <template v-for="keyIndex in indexList">
          <van-index-anchor :index="keyIndex" :key="keyIndex" />
          <template v-for="(option, index) in optionsMap[keyIndex]">
            <van-cell
              :title="option.label"
              :key="keyIndex + index"
              @click="handClick(option)"
            />
          </template>
        </template>
      </van-index-bar>
    </div>
  </div>
</template>
<script>
import { request } from "@/api/service";
import { keys } from "lodash";
export default {
  name: "BrandCodeSelect",
  props: {
    config: {
      type: Object,
      default: () => {},
    },
  },
  watch: {
    "config.val": {
      handler(newVal) {
        this.val = newVal;
      },
    },
    "config.options": {
      handler(newVal) {
        this.options = newVal;
      },
    },
  },
  data() {
    return {
      indexList: [],
      optionsMap: {},
      options: [],
    };
  },
  computed: {},
  created() {
    this.init();
  },
  methods: {
    async init() {
      const res = await request({
        url: "/afis-product/cmc/carBrand/dictCode/listAll",
        method: "get",
      });

      if (res && res.length > 0) {
        res.forEach((item) => {
          const letter =
            item.value &&
            item.value.charAt(0) &&
            item.value.charAt(0).toUpperCase();
          if (letter && !this.optionsMap[letter]) {
            this.optionsMap[letter] = [];
          }
          this.optionsMap[letter] && this.optionsMap[letter].push(item);
        });
      }
      this.indexList = keys(this.optionsMap);
    },
    handClick(option) {
      const { productCode } = this.$route.query;
      this.$router.push({
        path: `/product/order/index?productCode=${productCode}&brandCode=${option.value}`,
      });
    },
    onClickLeft() {
      const { productCode } = this.$route.query;
      this.$router.push({
        path: `/product/order/index?productCode=${productCode}`,
      });
    },
  },
};
</script>
